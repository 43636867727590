import React, { useEffect } from "react";
import { useSelector } from 'react-redux'

import { Switch, HashRouter as Router } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
//import "./assets/scss/theme.scss";
import './assets/css/bootstrap.css'
import './assets/css/bootstrap-override.css'
import './assets/css/topbar.css'
import './assets/css/verical.css'
import './assets/css/custom-scrollbar.css'
import './assets/css/global.css'
import './assets/css/datepicker.css'
import './assets/css/forms.css'
import "./assets/css/react-datepicker.css"

function App() {
  const loader = useSelector(state => state.loader)

  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
    document.addEventListener("keypress", function (event) {
      var invalidChars = ["+", "e"];
      if (document.activeElement.type === "number" && invalidChars.includes(event.key)) {
        event.preventDefault();
      }
    });
  }, [])

  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        theme="colored"
      />

      {loader &&
        <div id="loading-bg">
          <div className="loading">
            <div className="effect-1 effects"></div>
            <div className="effect-2 effects"></div>
            <div className="effect-3 effects"></div>
          </div>
        </div>
      }

      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </>
  );
};

export default App;